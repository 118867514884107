import React, {useEffect, useRef, useState} from 'react';
import {navigate} from 'gatsby';
import {useMutation} from '@apollo/react-hooks';
import {USER_LOGIN, USER_LOGIN_TWO_FACTOR} from '../../graphql/mutations';
import {
  getRawCookie,
  removeUserHash,
  removeUserToken,
  setDeviceKey,
  setRefreshToken,
  setUserHash,
  setUserToken,
} from '../../helpers';
import {UserLoginData, UserLoginState} from '../../types';
import {Translate} from '../translations';
import googleIcon from '../../images/google-icon.svg';
//import Checkbox from "../customCheckbox";
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Message from '../messages';
import ChangeToFactor from '../changeToFactor';
import {getPackageInfo} from '../registration';
import VerifyCode from '../verify-code';
import Checkbox from '../customCheckbox';
import {eyeIcon, lockClosedIcon, lockOpenIcon} from '../../images/svgIcons';
import './styles.scss';

const Login = ({
  //location,
  setShowLogin,
  setShowForgotPassword,
  setHandleCloseLoginModal,
  state,
}: any): JSX.Element => {
  const inputRef = useRef(null);

  //-------------------State-------------------
  const [showConfirmationCode, setShowConfirmationCode] = useState(false);
  const [showTwoFactor, setShowTwoFactor] = useState(false);
  const [code, setCode] = useState('');
  const [codeError, setCodeError] = useState('');
  //const [enableTwoFactor, setEnableTwoFactor] = useState(false);
  const [verificationType, setVerificationType] = useState('email');
  const [formData, setFormData] = useState<UserLoginState>({
    email: '',
    password: '',
    checked: false,
    error: false,
    errorMessage: '',
  });
  const [trusted, setTrusted] = useState(false);
  const [resendStatus, setResendStatus] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  //------------------Query--------------------
  const [loginMutation, {loading}] = useMutation<UserLoginData>(USER_LOGIN);
  const [verifyCode, {loading: verifyLoading}] = useMutation(USER_LOGIN_TWO_FACTOR);

  //----------useEffects------------------------
  useEffect(() => {
    resendStatus && setTimeout(() => setResendStatus(''), 4000);
  }, [resendStatus]);

  //----------------Functions-------------------
  const redirects = () => {
    if (getPackageInfo()) {
      navigate('/pricing/checkout');
    } else if (state?.navigatePath) {
      navigate(state.navigatePath);
    } else {
      if (window.location.host.includes('localhost')) {
        window.location.href = 'http://localhost:3000';
      } else if (window.location.host.includes('37.186.119.181')) {
        window.location.href = 'http://37.186.119.181:3279';
      } else {
        window.location.href = 'https://dash.hexomatic.com';
      }
    }
  };

  const submitHandler = async () => {
    setFormData({...formData, error: false, errorMessage: ''});
    const {data} = await loginMutation({
      variables: {email: formData.email, password: formData.password, productType: 'hexomatic'},
    });
    if (!data) return;
    const {
      UserOps: {
        login: {error, message, token, hash, twofa_status, device_key, refresh_token},
      },
    } = data;
    if (error) {
      setFormData({
        ...formData,
        error: true,
        errorMessage:
          message === 'TOO_MANY_ATTEMPTS'
            ? 'This account has been locked. Please use the forgot password link to unlock it.'
            : message,
      });
    } else {
      device_key && setDeviceKey(device_key);

      if (!token.includes('twofa_verify')) {
        setUserToken(token);
        setUserHash(hash);
        refresh_token && setRefreshToken(refresh_token);
        if (!twofa_status && Math.round(Math.random()) && !getPackageInfo()) {
          setShowTwoFactor(true);
          setHandleCloseLoginModal && setHandleCloseLoginModal(true);
        } else redirects();
        return;
      }
      setShowConfirmationCode(true);
      removeUserToken();
      removeUserHash();
      setVerificationType(token.includes('app') ? 'Google Authentication app' : 'email');
    }
  };

  // const checkHandler = (): void =>
  //   setFormData({
  //     ...formData,
  //     checked: !formData.checked,
  //   });

  const fieldChangeHandler = (event: React.SyntheticEvent): void => {
    const target = event.target as HTMLInputElement;
    const {value, name} = target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // global.document &&
  //   global.document.addEventListener("mousemove", (e) => {
  //     if (
  //       e.clientY < 20 &&
  //      () !== "false" &&
  //       getUserExists() !== "true"
  //     ) {
  //       setShowModal("true");
  //       //setShow("true");
  //     }
  //   });
  const handelKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') submitHandler();
  };
  const handelKeyDownEmail = (event: React.KeyboardEvent) => {
    //@ts-ignore
    if (event.key === 'Enter') inputRef.current.focus();
  };
  const toRegistration = () => {
    setShowLogin && setShowLogin(false);
    //setShowRegistration(true);
    navigate('/registration');
  };

  const goToReset = (e: any) => {
    e.preventDefault();
    setShowLogin && setShowLogin(false);
    //setShowRegistration(false);
    setShowForgotPassword(true);
  };
  // const handleChangeTwofactorModal = () => {
  //   setEnableTwoFactor(!enableTwoFactor);
  // };
  const handleVerifyCode = async () => {
    if (!code) {
      setCodeError('Invalid code');
      return;
    }
    const {data} = await verifyCode({
      variables: {
        code: code,
        email: formData.email,
        productType: 'hexomatic',
        rememberDevice: trusted,
        //base32: getRawCookie('base32'),
      },
    });
    if (data?.UserOps?.twofaVerify?.token) {
      setUserToken(data.UserOps.twofaVerify.token);
      setRefreshToken(data.UserOps.twofaVerify.refresh_token);
      setUserHash(data.UserOps.twofaVerify.hash);
      data.UserOps.twofaVerify.device_key && setDeviceKey(data.UserOps.twofaVerify.device_key);
      redirects();
    } else if (data.UserOps.twofaVerify.error) {
      setCodeError(
        data.UserOps.twofaVerify.message === 'INVALID_TWOFA_RESEND' ||
          data.UserOps.twofaVerify.message === 'CODE_SUCCESS_SENT'
          ? 'You have maxed out your attempts with your code. New generated code has been sent to your email.'
          : 'Invalid code',
      );
      setCode('');
    }
  };
  const resendCode = async () => {
    setCodeError('');
    const {data} = await verifyCode({
      variables: {
        code: 'send_to_email',
        email: formData.email,
        productType: 'hexomatic',
        base32: getRawCookie('base32'),
      },
    });
    if (data?.UserOps?.twofaVerify) {
      setResendStatus(data.UserOps.twofaVerify.message === 'CODE_SUCCESS_SENT' ? 'success' : 'error');
    }
  };
  const handleChangeTrusted = () => setTrusted(!trusted);

  useEffect(() => {
    if (showTwoFactor) {
      const el = document.getElementsByClassName('modal-body');
      el && el[0] && el[0].classList.add('modal-body-two-fa');
    } else {
      const el = document.getElementsByClassName('modal-body-two-fa');
      el && el[0] && el[0].classList.remove('modal-body-two-fa');
    }
  }, [showTwoFactor]);

  return (
    <div className="login-block">
      {showConfirmationCode ? (
        <>
          <div className="forms mt-2">
            <div className="form-item mb-1">
              <div className="d-flex justify-content-center mb-1">
                {!codeError && code.length === 6 ? lockOpenIcon : lockClosedIcon}
              </div>
              <h3 className="text-center">Enter verification code</h3>
              <VerifyCode
                length={6}
                setCode={setCode}
                //action={handleVerifyCode}
                setError={setCodeError}
                error={!!codeError}
              />
              <div className="text-center">
                {verificationType === 'email'
                  ? 'A code has been sent to your email, please check and enter the six-digit verification code into the field to access your account.'
                  : 'Please check and enter the six-digit verification code from the Google Authenticator app into the field to access your account.'}
              </div>
            </div>

            <div className={`d-flex justify-content-center align-items-center`}>
              <Checkbox checked={trusted} click={handleChangeTrusted} />
              <span onClick={handleChangeTrusted} className="ml-2 f_w_500">
                Remember this device
              </span>
            </div>
            <div className={`text-center ${codeError ? 'visible' : 'invisible'}`}>
              <Message text={codeError} type={'error'} />
            </div>
          </div>
          <div className="submit-block my-0">
            <button className="submit-btn w-50" onClick={handleVerifyCode}>
              {verifyLoading ? <FontAwesomeIcon icon={faSpinner} className="button-loading" /> : 'Verify'}
            </button>
          </div>
          <div className="clickable-text text-center pt-1 " id="resend-code" onClick={resendCode}>
            {verificationType === 'email' ? 'Resend code' : 'Send code to email'}
          </div>
          <div
            className={`clickable-text text-center pt-1 f_w_500 ${resendStatus ? 'visible' : 'invisible'} ${
              resendStatus === 'success' ? 'text-success' : 'text-danger'
            }`}
          >
            {resendStatus === 'success' ? 'Successfully sent' : 'Something went wrong'}
          </div>
        </>
      ) : showTwoFactor ? (
        <ChangeToFactor />
      ) : (
        <>
          <h4 className="title">Welcome back!</h4>
          <button
            className="gmail-btn"
            onClick={() => {
              if (window.location.host.includes('37.186.119.181')) {
                window.location.href = `http://37.186.119.181:4000/api/app/social-callback/hexomatic/google?deviceKey=${getRawCookie('device_key') || ''}${
                  getPackageInfo() ? '&redirect=pricing/checkout' : ''
                }`;
              } else if (window.location.host.includes('localhost')) {
                window.location.href = `http://37.186.119.181:4000/api/app/social-callback/hexomatic/google?deviceKey=${getRawCookie('device_key') || ''}${
                  getPackageInfo() ? '&redirect=pricing/checkout' : ''
                }`;
              } else {
                window.location.href = `https://api.hexomatic.com/v2/app/social-callback/hexomatic/google?deviceKey=${getRawCookie('device_key') || ''}${
                  getPackageInfo() ? '&redirect=pricing/checkout' : state?.navigatePath ? `&redirect=${state.navigatePath.slice(1)}` : ''
                }`;
              }
            }}
          >
            <img src={googleIcon} alt="Login with Google" width={30} /> <span>Sign in with Google</span>
          </button>
          <div className="or-div">
            <div>
              <Translate name="or" />
            </div>
            <hr />
          </div>
          <div className="forms">
            <div className="form-item">
              <label>Email</label>
              <input
                style={
                  formData.error
                    ? {
                        border: '1px',
                        borderColor: '#FB6F6F',
                        borderStyle: 'solid',
                      }
                    : {}
                }
                required
                placeholder={'Email'}
                type="email"
                name="email"
                onChange={fieldChangeHandler}
                onKeyDown={handelKeyDownEmail}
                value={formData.email}
              />
            </div>
            <div className="form-item position-relative password-form">
              <label>Password</label>
              <input
                style={
                  formData.error
                    ? {
                        border: '1px',
                        borderColor: '#FB6F6F',
                        borderStyle: 'solid',
                      }
                    : {}
                }
                ref={inputRef}
                required
                placeholder="Password"
                type={!showPassword ? 'password' : 'text'}
                name="password"
                onChange={fieldChangeHandler}
                onKeyDown={handelKeyDown}
                value={formData.password}
              />
              <span
                className={`eye-icon-pass ${showPassword ? ' active' : ''}`}
                onClick={() => setShowPassword(!showPassword)}
              >
                {eyeIcon}
              </span>
            </div>
            {formData.error && <Message type={'error'} text={<Translate name={formData.errorMessage} />} />}
          </div>
          <div className="keept-and-forgot-block">
            {/* <div className="toggle-checkbox">
          <Checkbox checked={formData.checked} click={checkHandler} />
          <span className="text">Keep me logged in?</span>
        </div> */}
            <div className="forgotten-password">
              <a onClick={goToReset}>
                <Translate name="FORGOT_PASSWORD_QUESTION" />
              </a>
            </div>
          </div>
          <div className="submit-block">
            <button className="submit-btn w-100" onClick={submitHandler}>
              {loading ? <FontAwesomeIcon icon={faSpinner} className="button-loading" /> : 'Login'}
            </button>
          </div>
          <div className="forgot">
            <span>Not a member?&nbsp;</span>
            <span className="create-accout-text" onClick={toRegistration}>
              <Translate name="CREATE_ACCOUNT" />
            </span>
          </div>
        </>
      )}
    </div>
  );
};

export default Login;
